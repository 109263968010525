import {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as _redux from '../redux/DigitalWaiverRedux'
import {v4 as uuidv4} from 'uuid'
import {LOGOUT_URL, ORGANIZATION_CODE} from '../../../../config/env'
import {useWebSocket} from '../../../components/utils/WebSocketProvider'
import {CustomerUserModel} from '../../../models/customer-portal/AuthModel'
import {useBreakpoint} from '../../../components/hooks/useBreakpoint'
import {Button} from '../../../components/inputs/Button'
import {MetronicIcon} from '../../../components/inputs/MetronicIcon'
import {IFRAME_GOOGLE, IFRAME_FACEBOOK, IFRAME_LOGIN_URL} from '../../../../config/env'
import {CookiesProps, useCookies} from '../hooks/useCookies'
import Cookies from 'js-cookie'
import {MyPassAuth, MyPassParams} from '../redux/DigitalWaiverCRUD'
import {OrDivider} from '../../../components/utils/OrDivider'
import {MyPassSignUp} from './MyPassSignUp'
import {LoginByTicketId} from './LoginByTIcketId'
import {IS_QUICK_WAIVER} from '../../../../config/env'

const UNIQUE_SOCKET_NAME = 'WEBNTECH_ADMM_WAIVER' + uuidv4()

export const COOKIES_DOMAINS_CP: CookiesProps[] = [
  {
    name: 'x-ms-cpim-trans',
    value: 'true',
    domain: 'https://farahb2csitext.b2clogin.com',
  },

  {
    name: 'x-ms-cpim-trans',
    value: 'true',
    domain: 'https://mypass.yasmarinacircuit.com',
  },

  {
    name: 'x-ms-cpim-csrf',
    value: 'true',
    domain: 'https://farahb2csitext.b2clogin.com',
  },

  {
    name: 'x-ms-cpim-csrf',
    value: 'true',
    domain: 'https://mypass.yasmarinacircuit.com',
  },
]

export interface FormikProps {
  username: string
  password: string
  otpValue: string
}

interface MyPassResponse {
  error?: string
  user?: CustomerUserModel
  token?: string
}

export type LoginType = 'myPass' | 'waiver' | 'normal'

const uri = window.location.href

export const MyPassLogin = () => {
  const {setCookie} = useCookies()
  const [loginType, setLoginType] = useState<LoginType>()
  const {socket, isConnected, startSocket} = useWebSocket()
  const dispatch = useDispatch()
  const [error, setError] = useState<string>()
  const [showSignUp, setShowSignUp] = useState<boolean>(false)
  const [googleFacebookSocketName, setGoogleFacebookSocketName] = useState<string>()
  const {down} = useBreakpoint()

  const uriState = useMemo(() => {
    const state = {
      organizationCode: ORGANIZATION_CODE,
      socket: UNIQUE_SOCKET_NAME,
      type: 'dw',
    }
    return encodeURIComponent(JSON.stringify(state))
  }, [])

  const pageState = useMemo(() => {
    const state = {
      organizationCode: ORGANIZATION_CODE,
      socket: UNIQUE_SOCKET_NAME,
      redirect: 'true',
      type: 'dw',
    }
    return encodeURIComponent(JSON.stringify(state))
  }, [])

  const myPassListener = useCallback(
    (data: MyPassResponse) => {
      if (data.token) {
        Cookies.remove('noTicket')
        dispatch(_redux.actions.auth.login(data.token))
      }
      if (data.error) {
        if (!Cookies.get('noTicket')) Cookies.set('noTicket', 'true')
        if (!IS_QUICK_WAIVER || IS_QUICK_WAIVER === 'FALSE') window.location.href = `${LOGOUT_URL}`
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (Cookies.get('noTicket') === 'true') {
      setError(
        'No ticket has been located for the account entered. Please sign-up or try again using the same credentials you created to purchase your tickets.'
      )
      Cookies.remove('noTicket')
    }
  }, [])

  useEffect(() => {
    if (Cookies.get('reloadMYPASS')) {
      Cookies.remove('reloadMYPASS')
      if (!IS_QUICK_WAIVER || IS_QUICK_WAIVER === 'FALSE') window.location.href = `${LOGOUT_URL}`
    }
  }, [])

  useEffect(() => {
    if (socket && UNIQUE_SOCKET_NAME) {
      socket?.on(UNIQUE_SOCKET_NAME, myPassListener)
      return () => {
        socket?.off(UNIQUE_SOCKET_NAME, myPassListener)
      }
    }
  }, [myPassListener, socket])

  useEffect(() => {
    if (socket && googleFacebookSocketName) {
      socket?.on(googleFacebookSocketName, myPassListener)
      return () => {
        socket?.off(googleFacebookSocketName, myPassListener)
      }
    }
  }, [googleFacebookSocketName, myPassListener, socket])

  const handleCallMyPass = useCallback(async ({state, code, id_token}: MyPassParams) => {
    if (
      decodeURIComponent(state) &&
      JSON.parse(decodeURIComponent(state)) &&
      JSON.parse(decodeURIComponent(state)) &&
      JSON.parse(decodeURIComponent(state)).socket
    ) {
      setGoogleFacebookSocketName(JSON.parse(decodeURIComponent(state)).socket)
      const timer = setTimeout(async () => {
        await MyPassAuth({state, code, id_token})
        clearTimeout(timer)
      }, 200)
    }
  }, [])

  useEffect(() => {
    if (isConnected) {
      const stateUri = uri.split('state=')
      if (stateUri.length > 1) {
        const encoded = decodeURIComponent(stateUri[1]).replace('/|yasconnect_ymc,', '').split('&')
        let state: string = '',
          code: string = '',
          id_token: string = ''
        if (encoded.length === 3) {
          if (encoded[0].includes('organizationCode')) {
            state = encodeURIComponent(encoded[0])
          }
          if (encoded[1].includes('code')) {
            code = encoded[1].replace('code=', '')
          }
          if (encoded[2].includes('id_token')) {
            id_token = encoded[2].replace('id_token=', '')
          }
        }

        if (state.length && code.length && id_token.length) {
          handleCallMyPass({state, code, id_token})
        }
      }
    }
  }, [handleCallMyPass, isConnected])

  useEffect(() => {
    const stopSocket = startSocket()
    return () => {
      stopSocket()
    }
  }, [startSocket])

  const loginFields = useMemo(() => {
    return (
      <>
        {!Cookies.get('cookies') ? (
          <div
            className='d-flex p-5 flex-column justify-content-center rounded bg-body'
            style={{
              minHeight: '400px',
            }}
          >
            <div className='p-5'>
              <p className='fw-bold fs-4 mb-0 text-white text-start px-4'>
                We use first and third-party cookies to offer you a better browsing experience. We
                also use cookies to provide you with personalised content and ads on this and other
                websites and social media. If you click ‘ALLOW’ you accept our cookies policy. You
                can control the cookies settings at any time. To know more, please refer to our{' '}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.yasmarinacircuit.com/legal#privacycookies'
                >
                  Cookie Policy
                </a>
                .
              </p>
            </div>
            <div className='text-end p-5'>
              <Button
                className='rounded-0 btn-primary'
                onClick={() => {
                  setCookie(COOKIES_DOMAINS_CP, 365)
                  window.parent.postMessage(
                    {
                      msg: 'message-content',
                    },
                    '*'
                  )
                  setTimeout(() => {
                    window.location.reload()
                  }, 500)
                }}
              >
                ALLOW
              </Button>
            </div>
          </div>
        ) : !error && isConnected ? (
          <div
            style={{
              minWidth: '250px',
            }}
          >
            <div>
              <div
                className='ff-admm-bold text-center'
                style={{
                  fontSize: '30px',
                  color: 'white',
                }}
              >
                DIGITAL WAIVER
              </div>
              <div className='px-2 text-start'>
                <p className='text-white px-5 mx-5 mt-3 text-uppercase ff-admm-bold'>
                  **Important Login Information**
                </p>
                <p className='text-white px-5 mx-5 mt-3'>
                  Your ticket & waiver are linked directly to the email address you provided at the
                  time of booking.
                </p>
                <p className='text-white px-5 mx-5 mt-3 ff-admm-bold'>PURCHASED ONLINE:</p>
                <p className='text-white px-5 mx-5 mt-3'>
                  If you purchased via YASMARINACIRCUIT.COM, you will have already set up and used a
                  MyPass account to complete the transaction. Please use those same credentials -
                  (email & password) - to access your ticket & waiver.
                </p>
                <p className='text-white px-5 mx-5 mt-3'>
                  If you purchased through our CALL CENTER or at our YAS MALL OUTLET, please Sign Up
                  Now and use the email id provided at the time of booking.
                </p>
              </div>

              <div className='d-flex flex-column h-100'>
                <div>
                  <p
                    className='text-white text-uppercase mb-0 ff-admm-bold'
                    style={{
                      lineHeight: '20px',
                      fontSize: '18px',
                      marginRight: !down('xs') ? '40px' : '25px',
                      marginLeft: !down('xs') ? '40px' : '25px',
                    }}
                  >
                    Sign in with
                  </p>
                </div>
                <div
                  className='d-flex gap-10 justify-content-center mb-3 py-5'
                  style={{
                    marginRight: !down('xs') ? '40px' : '25px',
                    marginLeft: !down('xs') ? '40px' : '25px',
                  }}
                >
                  <Button
                    className='w-50'
                    size='sm'
                    style={{
                      backgroundColor: '#db4437',
                      borderRadius: 0,
                    }}
                  >
                    <a
                      className='text-white d-flex justify-content-between'
                      href={`${IFRAME_GOOGLE}&state=${uriState}`}
                    >
                      Google
                      <img
                        width={!down('xs') ? '20px' : '15px'}
                        className='rounded-circle'
                        alt='facebook'
                        src='/media/svg/social-logos/google.svg'
                      />
                    </a>
                  </Button>

                  <Button
                    className='w-50'
                    size='sm'
                    style={{
                      backgroundColor: '#3b5998',
                      borderRadius: 0,
                    }}
                  >
                    <a
                      className='text-white d-flex justify-content-between'
                      href={`${IFRAME_FACEBOOK}&state=${uriState}`}
                    >
                      Facebook
                      <img
                        width={!down('xs') ? '20px' : '15px'}
                        className='rounded-circle'
                        alt='facebook'
                        src='/media/svg/social-logos/facebook-2.svg'
                      />
                    </a>
                  </Button>
                </div>

                <div>
                  <iframe
                    id='iframe-id'
                    width='100%'
                    height='550px'
                    title='iframe'
                    src={`${IFRAME_LOGIN_URL}&state=${uriState}`}
                  />
                </div>

                <OrDivider className='or-divider-cp mt-5' />
                <Button
                  variant='text'
                  className='text-primary btn-lg ff-admm-bold fs-1'
                  fullWidth
                  onClick={() => setShowSignUp(true)}
                >
                  Sign up now
                </Button>
                {!IS_QUICK_WAIVER && (
                  <Button
                    variant='text'
                    className='py-0 pt-3'
                    onClick={() => setLoginType('waiver')}
                  >
                    Go to Quick waiver login
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : error && isConnected ? (
          <div
            className='bg-body rounded d-flex flex-column justify-content-center p-5'
            style={{
              minHeight: '400px',
            }}
          >
            <div className='text-center '>
              <MetronicIcon
                size='default'
                iconType='Code'
                iconName='Stop'
                className='svg-icon svg-icon-danger svg-icon-5x'
                tooltip='Clear Selection'
              />
            </div>

            <div className='text-white fw-bolder text-center font-weight-bold fs-1 my-5'>
              WE'RE SORRY - NO TICKET LOCATED
            </div>
            <div className='text-white fw-bold text-center font-weight-bold fs-2'>{error}</div>
          </div>
        ) : null}
      </>
    )
  }, [down, error, isConnected, setCookie, uriState])

  return (
    <div className='form w-100 p-0 digital-waiver-container'>
      {IS_QUICK_WAIVER && IS_QUICK_WAIVER === 'TRUE' && <LoginByTicketId />}

      {IS_QUICK_WAIVER && IS_QUICK_WAIVER === 'FALSE' ? (
        !showSignUp ? (
          loginFields
        ) : showSignUp && pageState ? (
          <MyPassSignUp pageState={pageState} setShowSignUp={setShowSignUp} />
        ) : null
      ) : null}

      {!IS_QUICK_WAIVER ? (
        <>
          {loginType === 'myPass' ? (
            !showSignUp ? (
              <>{loginFields}</>
            ) : pageState ? (
              <MyPassSignUp pageState={pageState} setShowSignUp={setShowSignUp} />
            ) : null
          ) : loginType === 'waiver' ? (
            <LoginByTicketId setLoginType={setLoginType} />
          ) : (
            <div className='text-center'>
              <Button className='auth-dw-field' onClick={() => setLoginType('myPass')}>
                MyPass
              </Button>
              <OrDivider className='or-divider-cp mt-5' />
              <Button className='auth-dw-field mt-5' onClick={() => setLoginType('waiver')}>
                Quick waiver
              </Button>
            </div>
          )}
        </>
      ) : null}
    </div>
  )
}
